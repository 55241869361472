import { toast } from 'react-toastify';
import { Alert, Box, Button, ButtonGroup, Container, Modal, Stack, TextField, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from 'src/store/data/dataReducer';
const cookies = new Cookies();
function AddNewUser() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.data);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClick = async (e) => {
    e.preventDefault();
    if (firstName === '') {
      toast.error('First Name is required!');
      return;
    }
    if (lastName === '') {
      toast.error('Last Name is required!');
      return;
    }
    if (email === '') {
      toast.error('Email is required');
      return;
    }
    if (password === '') {
      toast.error('Password is required');
      return;
    }

    showLoader(true);

    await axios
      .post(`${API_URL}/api/auth/`, { firstName, lastName, email, password, isGuest, isAdmin })
      .then((res) => {
        toast.success(res.data?.message || 'User created successfully.');
        console.log([...users, res.data]);
        dispatch(setUsers([...users, res.data]));
        navigate('/user');
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response?.data?.message || 'Internal server error, please try again.');
        setOpen(true);
      });
    showLoader(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };
  return (
    <Container maxWidth="false" sx={{ padding: { xs: '0px' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600', m: 1 }}>
          New User
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleClick}
        className={classes.form}
        sx={{
          justifyContent: { xs: 'center', md: 'flex-start' }, 
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          label="First Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          label="Last Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type="text"
          margin="normal"
        />
        <FormControl className={classes.formFields}>
          <InputLabel id="type-select">Account Type</InputLabel>
          <Select
            labelId="type-select"
            value= {isGuest ? 'guest' : isAdmin ? 'admin' : 'tutor'}
            label= "Account Type"
            onChange={(e) => {
              if (e.target.value === 'guest') {
                setIsGuest(true);
                setIsAdmin(false);
              } else if (e.target.value === 'admin') {
                setIsGuest(false);
                setIsAdmin(true);
              } else {
                setIsGuest(false);
                setIsAdmin(false);
              }
            }}
          >
            <MenuItem value="guest">Parent/Student</MenuItem>
            <MenuItem value="tutor">Tutor</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
        {open ? (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Alert severity="error">{error}</Alert>
            </Box>
          </Modal>
        ) : (
          ''
        )}
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Create
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default AddNewUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
