import { toast } from 'react-toastify';
import { Alert, Box, Button, ButtonGroup, Container, Modal, Stack, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setPayments, setUsers } from 'src/store/data/dataReducer';
function AddNewPayment() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payments, users } = useSelector(state => state.data)
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClick = async (e) => {
    e.preventDefault();
    if (userId === '') {
      toast.error('Email is required');
      return;
    }

    showLoader(true);

    await axios
      .post(
        `${API_URL}/api/billings/new`,
        { userId },
      )
      .then((res) => {
        dispatch(setPayments([...payments, res.data]))
        let userIndex = users.findIndex(u => u._id === res.data.user)
        if(userIndex > -1) {
          let user = {...users[userIndex]}
          user.billedAt = res.data.createdAt
          let newUsers = [...users]
          newUsers.splice(userIndex, 1, user)
          dispatch(setUsers([...users]))
        }
        toast.success('Payment created successfully.');
        navigate('/payment');
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response?.data?.message);
        setOpen(true);
      });
    showLoader(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/payment');
  };

  return (
    <Container maxWidth="false" sx={{ padding: { xs: '0px' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Payment
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleClick}
        className={classes.form}
        flexDirection={'column'}
      >
        <FormControl className={classes.formFields} fullWidth sx={{ m: { xs: 0, md: 1 } }}>
          <InputLabel id="status-select">User Email</InputLabel>
          <Select
            labelId="status-select"
            value={userId}
            label="User Email"
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
          >
            {users.map((user, index) => {
              return <MenuItem key={index} value={user._id}>{user.email}</MenuItem>
            })}
          </Select>
        </FormControl>
        {open ? (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Alert severity="error">{error}</Alert>
            </Box>
          </Modal>
        ) : (
          ''
        )}
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Create
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default AddNewPayment;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%', 
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      marginTop: '10px',
      justifyContent: 'flex-end',
    },
  })
);
