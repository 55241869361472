import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Select,
  Stack,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../api';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from 'src/store/data/dataReducer';
import Iconify from 'src/components/iconify';

function UpdateUser() {
  const { users } = useSelector(state => state.data)
  const classes = useStyles();
  const { id: userId } = useParams()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(-1);
  const [isGuest, setIsGuest] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateUserStatus = async () => {
    showLoader(true);
    try {
      const response = await axios.patch(`${API_URL}/api/auth/${userId}`, { firstName, lastName, email, status, isGuest })
      const userIndex = users.findIndex(user => user._id === response.data._id)
      if(userIndex > -1) {
        let newUsers = [...users];
        newUsers.splice(userIndex, 1, response.data)
        dispatch(setUsers([...newUsers]))
      } else {
        dispatch(setUsers([...users, response.data]))
      }
      toast.success('Data of the user updated successfully.');
      navigate('/user');
    } catch (error) {
      console.log(error);
      toast.error(err.response?.data?.message || 'Internal server error!')
    }
    showLoader(false);
  };

  const fetchUser = async () => {
    showLoader(true);
    try {
      const res = await axios.get(`${API_URL}/api/auth/${userId}`)
      setFirstName(res.data?.firstName);
      setLastName(res.data?.lastName);
      setEmail(res.data?.email);
      setStatus(res.data?.status);
      setIsGuest(res.data?.isGuest)
    } catch (error) {
      console.log('error', error)
      toast.error(error.response?.data?.message || "Fetching info of user failed, please refresh the page")
    }
    showLoader(false);
  }

  useEffect(() => {fetchUser()}, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName === '') return toast.error('First Name is required');
    if (lastName === '') return toast.error('Last Name is required');
    if (email === '') return toast.error('Email is required');
    updateUserStatus();
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };

  return (
    <Container maxWidth="false" sx={{ paddingLeft: { xs: '0px', md: '16px' }, paddingRight: { xs: '0px', md: '16px' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update User Status
        </Typography>
        <Iconify onClick={(e) => back(e)} sx={{
          cursor: 'pointer'
        }} icon={'raphael:cross'} height={32} width={32} />
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          label="First Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          label="Last Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
          margin="normal"
        />
        <FormControl className={classes.formFields}>
          <InputLabel id="status-select">Status</InputLabel>
          <Select
            labelId="status-select"
            value={status}
            label="Status"
            onChange={(e) => setStatus(parseInt(e.target.value))}
          >
            <MenuItem value={-1}>Not Verified</MenuItem>
            <MenuItem value={0}>Pending Approval</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={2}>Disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formFields}>
          <InputLabel id="type-select">Account Type</InputLabel>
          <Select
            labelId="type-select"
            value={isGuest ? 'guest' : 'tutor'}
            label="Account Type"
            onChange={(e) => setIsGuest(e.target.value === 'guest')}
          >
            <MenuItem value="guest">Parent/student</MenuItem>
            <MenuItem value="tutor">Tutor</MenuItem>
          </Select>
        </FormControl>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Submit
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
