import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';
import Title from '../../components/title/Title';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  CardContent,
  Box,
  Button, CircularProgress, TextField, FormControl, Select, Tooltip, InputLabel,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
// components
import axios from 'axios';

import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import { API_URL } from 'src/api';
import { DeleteModal } from './DeleteModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfiles, fetchUsers } from 'src/store/data/dataAsyncThunks';
import {
  setFilteredProfiles, setFilteredProfilesAndUsersEmpty, setFilteredUsers, setSearchTextFilter, setStatusFilterGlobally, setAccountTypeFilterGlobally, setFiltersEmpty
} from 'src/store/data/dataReducer';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { RestartAltOutlined } from '@mui/icons-material';
import { getStatus } from 'src/utils/status';
// ----------------------------------------------------------------------
import { mkConfig, generateCsv, download } from "export-to-csv";

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false, minWidth: '120px' },
  { id: 'email', label: 'Email', alignRight: false, minWidth: '200px' },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'payment', label: 'Payment', alignRight: false },
  { id: 'isGuest', label: 'Account Type', alignRight: false },
  { id: 'createdAt', label: 'Created Date', alignRight: false, minWidth: '120px' },
  { id: 'actions', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Users() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentUser, setCurrentUser] = useState({});
  const [currentProfile, setCurrentProfile] = useState({});
  const {_id} = useSelector((state) => state.main);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const { users: usersFromGlobalState, profiles: profilesFromGlobalState, filteredUsers, filteredProfiles, filterBySearch, filterByStatus, filterByAcountType } = useSelector((state) => state.data);
  useEffect(() => {
    setCurrentUser(users.find((user) => user._id === currentId) || {});
    setCurrentProfile(profiles.find((profile) => profile?.user === currentId) || {});
  }, [currentId]);

  useEffect(() => {
    if (filteredUsers !== null || filteredProfiles !== null) {
      if (filteredUsers) {
        setUsers(filteredUsers)
      }
      if (filteredProfiles) {
        setProfiles(filteredProfiles)
      }
    } else {
      setUsers(usersFromGlobalState)
      setProfiles(profilesFromGlobalState)
    }
  }, [usersFromGlobalState, profilesFromGlobalState, filteredUsers, filteredProfiles]);

  useEffect(() => {
    handleSearch()
  }, [usersFromGlobalState, profilesFromGlobalState]);

  const usersOnPage = useMemo(
    () => users.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [page, rowsPerPage, users]
  );

  const displayData = usersOnPage
    .map((user) => {
      const { _id, firstName, lastName, email, status, billedAt, isGuest, isAdmin, createdAt } = user;

      const name = `${firstName} ${lastName}`;
      const payment = billedAt ? new Date(billedAt).toLocaleDateString('en-GB') : 'Not Paid';
      const tutorProfile = profiles.find((teacher) => teacher?.user === _id);
      const profileIndex = profiles.findIndex((teacher) => teacher?.user === _id);
        let hasProfile = profileIndex > -1;
        let userStatusId = '';
      return {
        _id,
        name,
        payment,
        email,
        isGuest,
        isAdmin,
        hasProfile,
        createdAt,
        userStatusId,
        ...getStatus(isGuest, isAdmin, status, !!tutorProfile ,tutorProfile?.status, tutorProfile?.isSubmitted),
      };
    })
    .sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      return order === 'asc' ? (aValue > bValue ? -1 : 1) : aValue < bValue ? -1 : 1;
    });

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const navigate = useNavigate();

  const showActivate = (id) => {
    const displayUser = displayData.find((user) => user._id === id)
    return displayUser && displayUser.showActivate
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, userId) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(userId);

    if (selectedIndex < 0) {
      // Add the userId to the selected array
      setSelected((prevSelected) => [...prevSelected, userId]);
    } else {
      // Remove the userId from the selected array
      setSelected((prevSelected) => prevSelected.filter((id) => id !== userId));
    }
  };

  const handleOpenMenu = (event, userId) => {
    setCurrentUser(users.find((user) => user._id === userId) || {})
    setOpen(event.currentTarget);
    setCurrentId(userId);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const createNew = (e) => {
    e.preventDefault();
    navigate('/user/new');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchTextFilter(filterBySearch))
    dispatch((setStatusFilterGlobally(filterByStatus)))
    dispatch(fetchUsers({ setLoadingUsers }));
    dispatch(fetchProfiles({ setLoadingProfiles }));
  }, []);

  const handleActivate = async () => {
    if (currentUser.status === 1) {
      try {
        await axios.post(`${API_URL}/api/auth/deactivate`, { userId: currentId });
        dispatch(fetchUsers({ setLoadingUsers }));
        dispatch(fetchProfiles({ setLoadingProfiles }));

      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message || 'Deactivating user failed.');
      }
    } else {
      try {
        await axios.post(`${API_URL}/api/auth/activate`, { userId: currentId });
        dispatch(fetchUsers({ setLoadingUsers }));
        dispatch(fetchProfiles({ setLoadingProfiles }));
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message || 'Activating user failed.');
      }
    }
  };

  const handleSearch = () => {
    setPage(0)
    let filteredUsers = usersFromGlobalState;

    if (filterBySearch !== '') {
      filteredUsers = filteredUsers.filter(user =>
        user.firstName?.toLowerCase().includes(filterBySearch) ||
        user.lastName.toLowerCase().includes(filterBySearch) ||
        (user.firstName + ' ' + user.lastName).toLowerCase().includes(filterBySearch) ||
        user.email.toLowerCase().includes(filterBySearch)
      );
    }

    if (filterByAcountType !== 'null') {
      filteredUsers = filteredUsers.filter(user =>
        (user.isGuest && filterByAcountType === 'student') ||
        (!user.isGuest && !user.isAdmin && filterByAcountType === 'tutor')
      );
    }

    if (filterByStatus !== 'null') {
      const {
        filteredProfiles, filteredUsersByProfile
      } = getFilteredProfilesByStatus(filteredUsers, profilesFromGlobalState, filterByStatus)
      dispatch(setFilteredProfiles(filteredProfiles))
      dispatch(setFilteredUsers(filteredUsersByProfile))
    } else {
      dispatch(setFilteredUsers(filteredUsers))
    }

    if (filterByStatus === 'null' && filterBySearch === '' && filterByAcountType === 'null') {
      dispatch(setFilteredProfilesAndUsersEmpty())
    }

  }

  const resetFilters = () => {
    dispatch(setStatusFilterGlobally('null'))
    dispatch(setSearchTextFilter(''))
    dispatch(setAccountTypeFilterGlobally('null'))
    dispatch(setFiltersEmpty())
    dispatch(setFilteredProfilesAndUsersEmpty())
  }

  const downLoadCSV = (data, filename) => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

   const getFilteredProfilesByStatus = (users, tutorProfiles, filterStatus) => {
    let filteredUsersByProfile = []
     const filteredProfiles = users.map((user) => {
         const { _id, status, isGuest, isAdmin } = user;
         const tutorProfile = tutorProfiles.find((teacher) => teacher?.user === _id);
         const {profileStatus} = getStatus(isGuest, isAdmin, status,!!tutorProfile, tutorProfile?.status, tutorProfile?.isSubmitted);
         if (filterStatus === profileStatus) {
           filteredUsersByProfile.push(user)
           return tutorProfile;
         }
       })

     return {
       filteredProfiles, filteredUsersByProfile
     }
   }

  const exportUsers = () => {
    if(users?.length) {
      downLoadCSV(users
        .map(user => {
          const { firstName, lastName, email } = user;
          const name = `${firstName} ${lastName}`;
          return { name, email };
        }), 'users.csv')
    }
  }
  
  const renderUserCards = (users) => {
    const cards = users.length ? users.map((user) => (
      <Card key={user._id} className="user-card" sx={{ display: { xs: 'block', md: 'none' }, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className="custom-grid-item">
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ width: '85%', wordBreak: 'break-word' }}>{user.name}</Typography>
                <Box sx={{ width: '15%' }}>
                  <IconButton size="small" color="inherit" onClick={(e) => handleOpenMenu(e, user._id)}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} className="custom-grid-item">
              <Typography variant="body2"><strong style={{ marginRight: '5px' }}>Email:</strong> {user.email}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 0 }} className="custom-grid-item">
              <Typography variant="body2">
                  <strong style={{ marginRight: '5px' }}>Status:</strong>
                  <Label color={user.profileColor} sx={{ fontFamily: 'Open Sans' }}>
                    {user.profile}
                  </Label>
              </Typography>
            </Grid>
            <Grid item xs={12} className="custom-grid-item">
              <Typography variant="body2"><strong style={{ marginRight: '5px' }}>Payment:</strong> {user.payment}</Typography>
            </Grid>
            <Grid item xs={12} className="custom-grid-item">
              <Typography variant="body2"><strong style={{ marginRight: '5px' }}>Account Type:</strong> {user.isGuest ? 'Student' : 'Tutor'}</Typography>
            </Grid>
            <Grid item xs={12} className="custom-grid-item">
              <Typography variant="body2"><strong style={{ marginRight: '5px' }}>Created Date:</strong> {new Date(user.createdAt).toLocaleDateString('en-GB')}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )) : <Card sx={{ p: 2, mb: 2 }}>No users found.</Card>;
    
    return cards;
  };
  
  return (
    <>
        <Title text="Users"/>

      <Container maxWidth="false" sx={{ paddingLeft: { xs: '0px', md: '16px' }, paddingRight: { xs: '0px', md: '16px' } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '24px', lg: '2.125rem' }, fontFamily: 'Open Sans', fontWeight: '600' }}>
            Users
          </Typography>
          <Button
            onClick={(e) => createNew(e)}
            variant="contained"
            color={"primary"}
            sx={{ padding: { xs: '8px 15px', md: '8px 22px' }, fontSize: { xs: '12px', md: '16px' }, borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            startIcon={<Iconify width={15} icon="eva:plus-fill" />}
          >
            Add User
          </Button>
        </Stack>

        {/*Filter Section*/}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" mb={2} sx={{ flexWrap: 'wrap' }}>
            <TextField label="Search User" placeholder="Search by name/email" sx={{ mr: { xs: 1, md: 2 }, mb: { xs: 2, md: 1 }, width: '200px' }} size="small" value={filterBySearch} onChange={(e) => dispatch(setSearchTextFilter(e.target.value.toLowerCase()))} />
            <FormControl fullWidth size="small"  sx={{ mr: { xs: 1, md: 2 }, width: '200px', mb: { xs: 2, md: 1 } }}>
                <InputLabel id="status-filter-select">Select Status</InputLabel>
                <Select
                  labelId="status-filter-select"
                  label="Select Status"
                  value={filterByStatus}
                  onChange={(e)=> dispatch(setStatusFilterGlobally(e.target.value))}>
                    <MenuItem value='null'>All</MenuItem>
                    <MenuItem value='not-verified'>Not Verified</MenuItem>
                    <MenuItem value='draft'>Draft</MenuItem>
                    <MenuItem value='pending'>Pending Approval</MenuItem>
                    <MenuItem value='approved'>Approved</MenuItem>
                    <MenuItem value='disabled'>Disabled</MenuItem>
                </Select>
          </FormControl>
  
            <FormControl fullWidth size="small" sx={{ mr: { xs: 1, md: 2 }, width: '150px', mb: { xs: 2, md: 1 }, }}>
              <InputLabel id="status-filter-select">Account Type</InputLabel>
              <Select
                labelId="status-filter-select"
                label="Account Type"
                value={filterByAcountType}
                onChange={(e)=> dispatch(setAccountTypeFilterGlobally(e.target.value))}>
                <MenuItem value='null'>All</MenuItem>
                <MenuItem value='tutor'>Tutor</MenuItem>
                <MenuItem value='student'>Student</MenuItem>
              </Select>
            </FormControl>
  
            <Button variant="outlined" size="medium" sx={{ mr: { xs: 1, md: 2 }, mb: { xs: 2, md: 1 }, }} onClick={handleSearch}>
              Search
            </Button>
            <Tooltip title="Reset Filter" placement="right-start">
              <IconButton onClick={resetFilters} sx={{ mb: { xs: 2, md: 1 } }}>
                <RestartAltOutlined color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export Tutors" placement="bottom">
              <Button onClick={() => exportUsers()} variant="outlined"
                size="medium"
                label="Download"
                sx={{
                  minWidth: '40px',
                  mb: { xs: 2, md: 1 },
                  display: { xs: 'flex', md: 'none' }
                }} startIcon={<FileDownloadOutlinedIcon color="primary" />}
              >
                Download
              </Button>
            </Tooltip>
          </Stack>
          <Box>
            <Stack direction="row" justifyContent="end" sx={{ flexWrap: 'wrap' }}>
              <Tooltip title="Export Tutors" placement="bottom">
                <Button onClick={() => exportUsers()} variant="outlined" size="medium" sx={{ mb: 1, display: { xs: 'none', md: 'flex' } }} startIcon={<FileDownloadOutlinedIcon color="primary" />}>
                  Download
                </Button>
              </Tooltip>
            {/*  <Tooltip title="Export Students" placement="bottom">*/}
            {/*  <Button onClick={() => exportStudents()} variant="outlined" size="medium" sx={{ ml: 1, mb: 1 }} startIcon={<FileDownloadOutlinedIcon color="primary" />}>*/}
            {/*    Students*/}
            {/*  </Button>*/}
            {/*</Tooltip>*/}
            </Stack>
          </Box>
        </Stack>
        {renderUserCards(displayData)}
        <Card sx={{ borderRadius: '12px' }}>
          <Scrollbar>
            <TableContainer sx={{ overflow: 'visible', position: 'relative' }}>
              <Table className="user-table">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {Array.isArray(displayData) &&
                    displayData?.map((user, index) => {
                      const { _id, name, email, payment, profile, profileColor, isGuest, isAdmin, createdAt } = user;
                      const createdAtDate = new Date(createdAt);
                      const formattedCreatedDate = createdAtDate.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: undefined,
                        timeZoneName: undefined,
                      });
                      const selectedUser = selected.findIndex((selectedId) => selectedId === _id) > -1;

                      return (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>
                          <TableCell scope="row" sx={{ wordBreak: 'break-all', maxWidth: '210px', fontFamily: 'Open Sans', fontSize: '14px', fontWeight: '600' }}>
                            {name}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', maxWidth: '250px', wordBreak: 'break-all' }}>
                            {email}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            <Label color={profileColor} sx={{ fontFamily: 'Open Sans' }}>
                              {profile}
                            </Label>
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            {payment}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            {isAdmin ? 'Admin' : isGuest ? 'Parent/Student' : 'Tutor'}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }} >
                            {formattedCreatedDate}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {displayData.length === 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} sx={{ textAlign: { xs: 'start', md: 'center' }, padding: '20px' }}>
                        <em>No Users found</em>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {!loadingProfiles || !loadingUsers ? '' : <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: '+99999999999',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                opacity: '0.5',
              }}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <CircularProgress />
                </div>
              </div>
              }
            </TableContainer>
          </Scrollbar>
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={() => setOpen(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 160,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
            onClick={() => setOpen(null)}
          >
            <Link to={`/user/${currentId}`}>
              <MenuItem sx={{ fontFamily: 'Open Sans' }}>
                <Iconify icon={'eva:person-fill'} sx={{ mr: 2 }} />
                Edit User
              </MenuItem>
            </Link>
            {currentUser.isAdmin || (
              <>
                <Link to={currentUser.isGuest ? `/user/update/${currentId}`:`/user/profile/${currentId}`}>
                  <MenuItem sx={{ fontFamily: 'Open Sans' }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit Profile
                  </MenuItem>
                </Link>
              </>
            )}
            {currentUser._id === _id || (showActivate(currentId) && (
              <MenuItem onClick={() => handleActivate(currentId)} sx={{ fontFamily: 'Open Sans' }}>
                <Iconify icon={'eva:checkmark-outline'} sx={{ mr: 2 }} />
                {currentUser.status !== 1 ? 'Activate' : 'Deactivate'}

              </MenuItem>))}
            {currentUser._id === _id || (
              <MenuItem
                onClick={() => handleDeleteModal(currentId)}
                sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
              >
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Delete
              </MenuItem>
            )}
          </Popover>
          <TablePagination
            rowsPerPageOptions={[2, 10, 25]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        
        <DeleteModal deleteModal={deleteModal} handleDeleteModal={handleDeleteModal} deleteId={deleteId} />
      </Container>
    </>
  );
}