import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../api';
import showLoader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import PriceSlider from 'src/components/PriceSlider';
import DistanceSlider from 'src/components/DistanceSlider';
import UploadAvatar from 'src/components/UploadAvatar/UploadAvatar';
import { fetchProfiles } from 'src/store/data/dataAsyncThunks';
import PlaceSelect from 'src/components/PostcodeSelect/PostcodeSelect';
import { countWords } from '../../utils/string';
import 'react-phone-number-input/style.css';
import WarningDialog from '../../components/Dialog/Warning';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import config from 'config';
import Iconify from 'src/components/iconify';

// const countries = ['AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];

function UpdateProfile() {
  const maxWords = 190;

  const classes = useStyles();
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, profiles } = useSelector((state) => state.data);
  const profile = profiles.find((p) => p.user === userId);

  const [level, setLevel] = useState({
    '7+/11+': false,
    Primary: false,
    Secondary: false,
    GCSE: false,
    'A-Level': false,
    'Degree/Adult': false,
  });

  const [open, setOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [price, setPrice] = useState([15, 100]);
  const [online, setOnline] = useState(false);
  const [faceToFace, setFaceToFace] = useState(false);
  const [distance, setDistance] = useState(3);
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState('');
  const [about, setAbout] = useState('');
  const [aboutWordCount, setAboutWordCount] = useState(countWords(about))
  const [gender, setGender] = useState(-1);
  const [mailError, setMailError] = useState(false)
  const [links, setLinks] = useState({
    facebook: null,
    website: null,
    x: null,
    instagram: null,
    linkedin: null,
  });
  const [linksError, setLinksError] = useState({
    facebook: false,
    website: false,
    x: false,
    instagram: false,
    linkedin: false,
  });
  const [qualifications, setQualifications] = useState({
    Degree: -1,
    'Qualified Teacher': -1,
    'Enhanced DBS': -1,
    Examiner: -1,
    Student: -1,
    'A-level Student': -1,
  });
  const [exam, setExam] = useState({ AQA: false, OCR: false, Edexcel: false, WJEC: false, CCEA: false, Other: false });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [count, setCount] = useState(0);
  const [isAgency, setIsAgency] = useState(0);
  const [publish, setPublish] = useState(-1);
  const [latitude, setLatitude] = useState(-1);
  const [longitude, setLongitude] = useState(-1);
  const [postcode, setPostcode] = useState(null);
  const [addressDetails, setAddressDetails] = useState({});
  // const [status, setStatus] = useState(-2); // -1:draft, 0:submitted, 1:approved, 2:deleted
  const [phoneError, setPhoneError] = useState(false)

  useEffect(() => {
    if (profile) {
      setLevel(profile.level);
      setPrice(profile.price);
      setOnline(profile.online);
      setFaceToFace(profile.faceToFace);
      setDistance(profile.distance);
      setTitle(profile.title);
      setAvatar(profile.avatar);
      setAbout(profile.about);
      setGender(profile.gender);
      setLinks({...links, ...profile.links});
      setQualifications(profile.qualifications);
      setPhone(profile.phone);
      setEmail(profile.email);
      setCount(profile.count);
      setIsAgency(profile.isAgency);
      setPublish(profile.publish);
      setExam(profile.exam);
      setLatitude(profile.latitude);
      setLongitude(profile.longitude);
      setAddressDetails(profile.addressDetails);
      setPostcode(profile.postcode);
      // setStatus(profile.status);
    }
  }, [profile]);

  useEffect(() => {
    setAboutWordCount(countWords(about))
  }, [about]);

  const handleSetAbout = (value, old, source) => {
    // const wordCount = countWords(value);
    setAbout(value);
    // If word count exceeds the maximum allowed words, truncate the content
    // if (wordCount > maxWords) {
    //   const truncatedContent = value.split(/\s+/).slice(0, maxWords).join(' ');
    //   console.log(truncatedContent);
    //   setAbout(truncatedContent);
    // } else {
    //   setAbout(value);
    // }

    // if(countWords(value) < 191) {
    //   setAbout(value)
    // }
  }

  const UpdateProfileStatus = async () => {
    showLoader(true);

    const newProfile = {
      level,
      price,
      online,
      faceToFace,
      distance,
      title,
      avatar,
      about,
      gender,
      qualifications,
      phone,
      email,
      count,
      isAgency,
      links,
      publish,
      exam,
      postcode,
      latitude,
      longitude,
      addressDetails,
      // location,
      user: userId,
      // status,
    };
    try {
      const response = await axios.put(`${API_URL}/api/teachers/`, newProfile);
      dispatch(fetchProfiles());
      toast.success('Data of the user updated successfully.');
      navigate('/user');
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'Profile Submit Failed.');
    }
    showLoader(false);
  };

  const validateLink = (linkName, linkValue) => {
    let isValid = false
    switch (linkName) {
      case 'facebook':
        isValid = /(?:https?:\/\/)?(?:www\.)?(?:facebook\.com)\/(?:[^\/?]+\/?|groups\/[^\/?]+\/?|pages\/[^\/?]+\/?)/gm.test(linkValue)
        break
      case 'instagram':
        isValid = /(?:https?:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/(?:[^\/?]+\/?|p\/[^\/?]+\/?|tv\/[^\/?]+\/?)/gm.test(linkValue)
        break
      case 'website':
        isValid = /^(?:https?:\/\/)?(?:www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/gm.test(linkValue);
        break
      case 'linkedin':
        isValid = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$.*/i.test(linkValue);
        break
      case 'x':
        const isValidTwitter = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$.*/i.test(linkValue)
        const isValidX = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+\/?$.*/i.test(linkValue)

        isValid = isValidTwitter || isValidX
        break
      default:
        break
    }
    return isValid
  }
  const checkLinks = () => {
    if (links.facebook === '') {

      toast.error('Please input facebook URL')
      return false
    } else if(links.facebook) {
      if (!validateLink('facebook', links.facebook)) {
        toast.error('Please enter a valid facebook URL');
        return false;
      }
    }
    if(links.instagram === '') {
      toast.error('Please input instagram URL')
      return false
    } else if(links.instagram) {
      if (!validateLink('instagram', links.instagram)) {
        toast.error('Please enter a valid instagram URL');
        return false;
      }
    }
    if(links.linkedin === '') {
      toast.error('Please input linkedin URL')
      return false
    } else if(links.linkedin) {
      if (!validateLink('linkedin', links.linkedin)) {
        toast.error('Please enter a valid linkedin URL');
        return false;
      }
    }
    if(links.x === '') {
      toast.error('Please input twitter/x URL')
      return false
    } else if(links.x) {
      if (!validateLink('x', links.x)) {
        toast.error('Please enter a valid X link');
        return false
      }
    }
    if(links.website === '') {
      toast.error('Please enter a valid Website URL')
      return false
    } else if(links.website) {
      if (!validateLink('website', links.website)) {
        toast.error('Please enter a valid Website link');
        return false
      }
    }
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mailError) return toast.error('Invalid email, please enter correct email.');
    if (faceToFace && !postcode) return toast.error('Please enter postcode.');
    // if (aboutWordCount > 190) return toast.error('"About" must be less than 190 words');
    if (!checkLinks()) return;
    UpdateProfileStatus();
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };

  const formLabel = (label, subLabel = '') => (
    <FormLabel style={{ color: 'black', marginBottom: '0.5rem' }}>
      <strong>{label}</strong> {subLabel && `: ${subLabel}`}
    </FormLabel>
  );

  const handleLink = (linkName, value) => {

    if (value) {
      setLinks({ ...links, [linkName]: '' });
    } else {
      setLinks({ ...links, [linkName]: null });
    }
    setLinksError({ ...linksError, [linkName]: false })
  };

  // const handlePhoneChange = (value) => {
  //   setPhone(value);
  //   if (value) {
  //     if(isValidPhoneNumber(value) || isPossiblePhoneNumber(value)) {
  //       setPhoneError(false)
  //     } else if (value?.length <= 5 && phoneError){
  //       setPhone('')
  //       setPhoneError(false)
  //     } else {
  //       setPhoneError(true)
  //     }
  //   } else {
  //     setPhoneError(false)
  //   }
  // }

  const handleCloseWarningDialog = () => {
    setOpen(false)
  }
  // const handleOpenWarningDialog = () => {
  //   setOpen(true)
  // }

  // const handleSelectStatus = (e) => {
  //   if (e.target.value === -1) {
  //     handleOpenWarningDialog()
  //     return
  //   }
  //   setStatus(parseInt(e.target.value))
  // }

  // useEffect(() => {
  //   if (isConfirm) {
  //     setStatus(-1)
  //   }
  // }, [isConfirm]);

  const handleUpdateLink = (e, type) => {
    setLinks({ ...links, [type]: e.target.value })
    const isValid = validateLink(type, e.target.value)
    if (e.target.value) {
      if (isValid) {
        setLinksError({ ...linksError, [type]: false })
      } else {
        setLinksError({ ...linksError, [type]: true })
      }
    } else {
      setLinksError({ ...linksError, [type]: false })
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if (e.target.value) {
      const isValid = !e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true
      if (!isValid) {
        setMailError(true)
        dispatch(setEmail(e.target.value))
      } else {
        setMailError(false)
        dispatch(setEmail(e.target.value))
      }
    } else {
      setMailError(false)
      dispatch(setEmail(e.target.value))
    }
  }

  const modules = {
    toolbar: [
      [{ 'list': 'bullet' }],
    ],
  };

  const formats = [
    'list', 'bullet',
  ];

  const handleChange = (value) => {
    const plainText = value.replace(/<[^>]*>/g, '').trim();

    const wordCount = plainText.split(/\s+/).length;

    if (wordCount > maxWords) {
      const truncatedContent = plainText.split(/\s+/).slice(0, maxWords).join(' ');
      setAbout(truncatedContent);
    } else {
      setAbout(value);
    }
  };


  return (
    <Container maxWidth="false" sx={{ paddingLeft: { xs: '0px', md: '16px' }, paddingRight: { xs: '0px', md: '16px' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Profile
        </Typography>
        <Iconify onClick={(e) => back(e)} sx={{
          cursor: 'pointer'
        }} icon={'raphael:cross'} height={32} width={32} />
      </Stack>
      {(
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          fullWidth
        >
          <FormGroup fullWidth>
            <FormLabel style={{ color: 'black', textTransform: 'capitalize' }}>
              <strong>{config['class-name']} Level</strong>
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={level['7+/11+']}
                  onChange={(e) => setLevel({ ...level, '7+/11+': e.target.checked })}
                />
              }
              label="7+/11+"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={level['Primary']}
                  onChange={(e) => setLevel({ ...level, Primary: e.target.checked })}
                />
              }
              label="Primary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={level['Secondary']}
                  onChange={(e) => setLevel({ ...level, Secondary: e.target.checked })}
                />
              }
              label="Secondary"
            />
            <FormControlLabel
              control={
                <Checkbox checked={level['GCSE']} onChange={(e) => setLevel({ ...level, GCSE: e.target.checked })} />
              }
              label="GCSE"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={level['A-Level']}
                  onChange={(e) => setLevel({ ...level, 'A-Level': e.target.checked })}
                />
              }
              label="A-Level"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={level['Degree/Adult']}
                  onChange={(e) => setLevel({ ...level, 'Degree/Adult': e.target.checked })}
                />
              }
              label="Degree/Adult"
            />
          </FormGroup>
          <FormGroup fullWidth>
            <FormLabel style={{ color: 'black' }}>
              <strong>Exam Board</strong>
            </FormLabel>
            <FormControlLabel
              control={<Checkbox checked={exam['AQA']} onChange={(e) => setExam({ ...exam, 'AQA': e.target.checked })} />}
              label="AQA"
            />
            <FormControlLabel
              control={<Checkbox checked={exam['OCR']} onChange={(e) => setExam({ ...exam, 'OCR': e.target.checked })} />}
              label="OCR"
            />
            <FormControlLabel
              control={
                <Checkbox checked={exam['Edexcel']} onChange={(e) => setExam({ ...exam, 'Edexcel': e.target.checked })} />
              }
              label="Edexcel"
            />
            <FormControlLabel
              control={
                <Checkbox checked={exam['WJEC']} onChange={(e) => setExam({ ...exam, 'WJEC': e.target.checked })} />
              }
              label="WJEC"
            />
            <FormControlLabel
              control={
                <Checkbox checked={exam['CCEA']} onChange={(e) => setExam({ ...exam, 'CCEA': e.target.checked })} />
              }
              label="CCEA"
            />
            <FormControlLabel
              control={
                <Checkbox checked={exam['Other']} onChange={(e) => setExam({ ...level, 'Other': e.target.checked })} />
              }
              label="Other"
            />
          </FormGroup>
          <PriceSlider price={price} setPrice={setPrice} />
          <FormControl style={{ marginTop: '1rem' }} fullWidth>
            {formLabel('Private tutoring options')}
            <FormGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ justifyContent: 'flex-start' }}
            >
              <FormControlLabel
                control={<Checkbox checked={online} onChange={(e) => setOnline(e.target.checked)} />}
                label="Online"
              />
              <FormControlLabel
                control={<Checkbox checked={faceToFace} onChange={(e) => setFaceToFace(e.target.checked)} />}
                label="In-person"
              />
            </FormGroup>
          </FormControl>
          {faceToFace && (
            <>
              <FormControl fullWidth>
                {formLabel('Postcode', postcode)}
                <PlaceSelect
                  onChange={(e) => {
                    if (!e.target.value) return;
                    setLatitude(e.target.value.latitude);
                    setLongitude(e.target.value.longitude);
                    setPostcode(e.target.value.postcode);
                    setAddressDetails(e.target.value.addressDetails);
                  }}
                />
              </FormControl>
              <DistanceSlider distance={distance} setDistance={setDistance} />
            </>
          )}
          {/*<FormControl className={classes.formFields} fullWidth>*/}
          {/*  {formLabel('Agency or Private')}*/}
          {/*  <Select labelId="status-select" value={isAgency} onChange={(e) => setIsAgency(parseInt(e.target.value))}>*/}
          {/*    <MenuItem value={-1}>Not Answered</MenuItem>*/}
          {/*    <MenuItem value={0}>Private</MenuItem>*/}
          {/*    <MenuItem value={1}>Agency</MenuItem>*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          <FormControl fullWidth>
            {formLabel('Title')}
            <TextField value={title} onChange={(e) => setTitle(e.target.value)} type="text" margin="none" fullWidth />
          </FormControl>
          <FormControl fullWidth style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
            <div style={{ height: '200px', width: '200px' }}>
              <UploadAvatar avatar={avatar} setAvatar={setAvatar} userId={userId} />
            </div>
          </FormControl>

          <FormControl fullWidth style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            margin: '0px !important',
            marginTop: '1rem !important',
            marginBottom: '1rem !important'
          }}>
            {formLabel('About')}
            {/*<TextField*/}
            {/*  value={about}*/}
            {/*  onChange={(e) => handleSetAbout(e.target.value)}*/}
            {/*  fullWidth*/}
            {/*  multiline*/}
            {/*  rows={5}*/}
            {/*  style={{borderColor: 1000 - about.length < 0 ? 'red' : 'lightgray'}}*/}
            {/*/>*/}

              <ReactQuill
                theme="snow"
                value={about}
                modules={modules}
                placeholder="Write about tutor"
                formats={formats}
                bounds={'.app-container'}
                onChange={handleSetAbout}
                style={{ margin: '0'}}
              />
            {/*<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>*/}
            {/*  <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>*/}
            {/*    <Typography*/}
            {/*      style={{*/}
            {/*        fontSize: '14px',*/}
            {/*        color: aboutWordCount < maxWords ? 'green' : '#da1e28',*/}
            {/*        marginTop: '0px',*/}
            {/*        marginRight: '10px',*/}
            {/*        marginBottom: '0px',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {aboutWordCount < maxWords ? `${maxWords - aboutWordCount} words left.` : 'You have reached your maximum words.'}*/}
            {/*    </Typography>*/}
            {/*    {aboutWordCount < maxWords ? (*/}
            {/*      <CheckCircleOutlineIcon fontSize="inherit" style={{ color: 'green', marginLeft: '2px' }}/>*/}
            {/*    ) : (*/}
            {/*      <CheckCircleIcon fontSize="inherit" style={{ color: '#da1e28', marginLeft: '2px' }}/>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</Box>*/}
          </FormControl>
          <FormControl className={classes.formFields} fullWidth>
            {formLabel('Gender')}
            <Select labelId="status-select" value={gender} onChange={(e) => setGender(parseInt(e.target.value))}>
              <MenuItem value={-1}>Not Selected</MenuItem>
              <MenuItem value={0}>Male</MenuItem>
              <MenuItem value={1}>Female</MenuItem>
              <MenuItem value={2}>Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ marginTop: '1rem' }} fullWidth>
            {formLabel('Social Links')}
            <FormGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={links.facebook !== null}
                      onChange={(e) => handleLink('facebook', e.target.checked)}
                    />
                  }
                  label="Facebook"
                  style={{ width: '100px' }}
                />
                {links.facebook !== null && (
                  <div style={{width: '80%'}}>
                    <TextField
                      error={linksError.facebook}
                      value={links.facebook}
                      onChange={(e) => handleUpdateLink(e, 'facebook')}
                      type="text"
                      style={{ flex: 1 , marginLeft: '10px', width: '50%'}}
                      size="small"
                    />
                    {linksError.facebook &&
                      <p style={{
                        marginLeft: '10px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        fontSize: '14px',
                        color: 'red'
                      }}>Hey, this seems wrong, please enter the correct URL format for your facebook profile.</p>
                    }
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={links.linkedin !== null}
                      onChange={(e) => handleLink('linkedin', e.target.checked)}
                    />
                  }
                  label="Linkedin"
                  style={{ width: '100px' }}
                />
                {links.linkedin !== null && (
                  <div style={{width: '80%'}}>
                    <TextField
                      value={links.linkedin}
                      error={linksError.linkedin}
                      onChange={(e) => handleUpdateLink(e, 'linkedin')}
                      type="text"
                      size="small"
                      style={{ flex: 1 , marginLeft: '10px', width: '50%'}}
                    />
                    {linksError.linkedin &&
                      <p style={{
                        marginLeft: '10px',
                        marginTop: '0px',
                        fontSize: '14px',
                        marginBottom: '0px',
                        color: 'red'
                      }}>Hey, this seems wrong, please enter the correct URL format for your linkedin profile.</p>
                    }
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={links.x !== null}
                      onChange={(e) => handleLink('x', e.target.checked)}
                    />
                  }
                  label="X"
                  style={{ width: '100px' }}
                />
                {links.x !== null && (
                  <div style={{width: '80%'}}>
                    <TextField
                      value={links.x}
                      error={linksError.x}
                      onChange={(e) => handleUpdateLink(e, 'x')}
                      type="text"
                      size="small"
                      style={{ flex: 1 , marginLeft: '10px', width: '50%'}}
                    />
                    {linksError.x &&
                      <p style={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        marginLeft: '10px',
                        fontSize: '14px',
                        color: 'red'
                      }}>Hey, this seems wrong, please enter the correct URL format for your twitter/x profile.</p>
                    }
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={links.instagram !== null}
                      onChange={(e) => handleLink('instagram', e.target.checked)}
                    />
                  }
                  label="Instagram"
                  style={{width: '100px'}}
                />
                {links.instagram !== null && (
                  <div style={{width: '80%'}}>
                    <TextField
                      value={links.instagram}
                      error={linksError.instagram}
                      onChange={(e) => handleUpdateLink(e, 'instagram')}
                      type="text"
                      size="small"
                      style={{ flex: 1, marginLeft: '10px', width: '50%' }}
                    />
                    {linksError.instagram &&
                      <p style={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        marginLeft: '10px',
                        fontSize: '14px',
                        color: 'red'
                      }}>Hey, this seems wrong, please enter the correct URL format for your instagram profile.</p>
                    }
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={links.website !== null}
                      onChange={(e) => handleLink('website', e.target.checked)}
                    />
                  }
                  label="Website"
                  style={{ width: '100px' }}
                />
                {links.website !== null && (
                  <div style={{width: '80%'}}>
                    <TextField
                      value={links.website}
                      error={linksError.website}
                      onChange={(e) => handleUpdateLink(e, 'website')}
                      type="text"
                      size="small"
                      style={{ flex: 1, marginLeft: '10px', width: '50%' }}
                    />
                    {linksError.website &&
                      <p style={{
                        marginTop: '0px',
                        marginLeft: '10px',
                        marginBottom: '0px',
                        fontSize: '14px',
                        color: 'red'
                      }}>Hey, this seems wrong, please enter the correct URL format of your website.</p>
                    }
                  </div>
                )}
              </div>
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginTop: '1rem' }} fullWidth>
            <div style={{ marginBottom: '15px' }}>
              {formLabel('Qualifications')}
            </div>
            <FormControl fullWidth style={{ marginBottom: '1rem' }}>
              <InputLabel id="status-select">Degree</InputLabel>
              <Select
                labelId="status-select"
                value={qualifications.Degree}
                label="Degree"
                onChange={(e) => setQualifications({ ...qualifications, Degree: parseInt(e.target.value) })}
              >
                <MenuItem value={-1}>Not Answered</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '1rem' }}>
              <InputLabel id="status-select">Qualified Teacher</InputLabel>
              <Select
                labelId="status-select"
                value={qualifications['Qualified Teacher']}
                label="Qualified Teacher"
                onChange={(e) => setQualifications({ ...qualifications, 'Qualified Teacher': parseInt(e.target.value) })}
              >
                <MenuItem value={-1}>Not Answered</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '1rem' }}>
              <InputLabel id="status-select">Enhanced DBS</InputLabel>
              <Select
                labelId="status-select"
                value={qualifications['Enhanced DBS']}
                label="Enhanced DBS"
                onChange={(e) => setQualifications({ ...qualifications, 'Enhanced DBS': parseInt(e.target.value) })}
              >
                <MenuItem value={-1}>Not Answered</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '1rem' }}>
              <InputLabel id="status-select">Examiner</InputLabel>
              <Select
                labelId="status-select"
                value={qualifications.Examiner}
                label="Examiner"
                onChange={(e) => setQualifications({ ...qualifications, Examiner: parseInt(e.target.value) })}
              >
                <MenuItem value={-1}>Not Answered</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '1rem' }}>
              <InputLabel id="status-select">Student</InputLabel>
              <Select
                labelId="status-select"
                value={qualifications.Student}
                label="Student"
                onChange={(e) => setQualifications({ ...qualifications, Student: parseInt(e.target.value) })}
              >
                <MenuItem value={-1}>Not Answered</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            {qualifications.Student > 0 && (
              <>
                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                  <InputLabel id="status-select">A-level Student</InputLabel>
                  <Select
                    labelId="status-select"
                    value={qualifications['A-level Student']}
                    label="A-level Student"
                    onChange={(e) =>
                      setQualifications({ ...qualifications, 'A-level Student': parseInt(e.target.value) })
                    }
                  >
                    <MenuItem value={-1} disabled>Not Answered</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                  <InputLabel id="status-select">University Student</InputLabel>
                  <Select
                    labelId="status-select"
                    value={qualifications['A-level Student']}
                    label="University Student"
                    onChange={(e) =>
                      setQualifications({ ...qualifications, 'A-level Student': parseInt(e.target.value) })
                    }
                  >
                    <MenuItem value={-1} disabled>Not Answered</MenuItem>
                    <MenuItem value={0}>Yes</MenuItem>
                    <MenuItem value={1}>No</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </FormControl>
          <FormControl fullWidth>
            {profile ? formLabel('Email - (Not editable)') : formLabel('Email')}
            <TextField
              disabled={!!profile}
              value={email}
              onChange={handleEmailChange}
              placeholder={profile ? 'Tutor has not entered his email' : 'Enter email'}
              type="text"
              margin="none"
              error={mailError}
              fullWidth
            />
            { mailError &&
              <p style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '14px',
                color: 'red',
              }}>Please enter valid email</p>
            }
          </FormControl>

          <FormControl style={{ marginTop: '1rem', position: 'relative', width: '100%' }}>
            {formLabel('Phone')}
            <TextField variant="outlined" placeholder="Enter your phone" type="number" hiddenLabel
                       style={{ margin: '0' }}
                       onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                       value={phone} onChange={(e) => setPhone(e.target.value)} />
            {/*<PhoneInput*/}
            {/*    placeholder="Enter phone number"*/}
            {/*    focusInputOnCountrySelection={true}*/}
            {/*    countries={countries}*/}
            {/*    defaultCountry="GB"*/}
            {/*    limitMaxLength={true}*/}
            {/*    value={formatPhoneNumberIntl(phone)}*/}
            {/*    onChange={handlePhoneChange}*/}
            {/*/>*/}
            {/*<span style={{ position: 'absolute', cursor: 'pointer', right: '10px', top: '50px' }} onClick={() => setPhone('')}>*/}
            {/*  <Cancel fontSize="small" />*/}
            {/*</span>*/}
            {/*{phoneError && <Typography variant="caption" color={'error'}>Phone Number is incorrect</Typography>}*/}

          </FormControl>

          {/*<FormControl className={classes.formFields} fullWidth>*/}
          {/*  {formLabel('Allow to Publish')}*/}
          {/*  <Select labelId="status-select" value={publish} onChange={(e) => setPublish(parseInt(e.target.value))}>*/}
          {/*    <MenuItem value={-1}>Not Answered</MenuItem>*/}
          {/*    <MenuItem value={0}>No</MenuItem>*/}
          {/*    <MenuItem value={1}>Yes</MenuItem>*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<FormControl className={classes.formFields} fullWidth>*/}
          {/*  {formLabel('Status')}*/}
          {/*  <Select labelId="status-select" value={status} onChange={handleSelectStatus}>*/}
          {/*    { profile && <MenuItem value={1}>Approve</MenuItem> }*/}
          {/*    { profile && <MenuItem value={2}>Deactivate</MenuItem>}*/}
          {/*    <MenuItem value={0}>Draft</MenuItem>*/}
          {/*    { profile && <MenuItem value={-1}>Delete</MenuItem>}*/}

          {/*    /!*-1:draft, 0:submitted, 1:approved, 2:deleted*!/*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
              <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
                Back
              </Button>
              <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
                Submit
              </Button>
            </ButtonGroup>
          </div>
        </Box>
      )}
      <WarningDialog open={open} setIsConfirm={setIsConfirm} handleClose={handleCloseWarningDialog} title="Delete Tutor Profile" content="This action won't be reversable. Are you sure you want to delete this tutor?"  />
    </Container>
  );
}

export default UpdateProfile;

const useStyles = makeStyles((theme) =>
  createStyles({
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
